<template>
  <div class="contact">
    <h5>blackchip <br />{{$t('CooperationContact.1')}}</h5>
    <p>
      {{$t('CooperationContact.2')}}, <br />{{$t('CooperationContact.3')}}.
    </p>
    <p>{{$t('CooperationContact.4')}}.</p>
    <b-form class="form-contact" @submit.prevent="onSendCooperationContact">
      <div class="item-input">
        <p>{{$t('InforUser.35')}}</p>
        <b-form-input
          :placeholder="$t('InforUser.35')"
          v-model="formContact.email"
          required
          type="email"
        ></b-form-input>
      </div>
      <div class="item-input">
        <p>{{$t('CooperationContact.5')}}</p>
        <b-form-input
          :placeholder="$t('CooperationContact.5')"
          v-model="formContact.name"
          required
          type="text"
        ></b-form-input>
      </div>
      <div class="item-input">
        <p>{{$t('CooperationContact.6')}}</p>
        <b-form-input
          :placeholder="$t('CooperationContact.6')"
          v-model="formContact.project_name"
          required
        ></b-form-input>
      </div>
      <div class="item-input">
        <p>{{$t('CooperationContact.7')}}</p>
        <b-form-input
          :placeholder="$t('CooperationContact.7')"
          v-model="formContact.project_website"
          required
        ></b-form-input>
      </div>
      <div class="item-input">
        <p>{{$t('CooperationContact.8')}} ?</p>
        <b-form-group>
          <b-form-radio
            required
            v-model="formContact.with_link"
            name="some-radios"
            :value="1"
            >Swap Pancakeswap</b-form-radio
          >
          <b-form-radio
            required
            v-model="formContact.with_link"
            name="some-radios"
            :value="2"
            >Reciprocal USDT</b-form-radio
          >
        </b-form-group>
      </div>
      <div class="item-input">
        <p>{{$t('listing.7')}}</p>
        <b-form-input
          :placeholder="$t('listing.7')"
          v-model="formContact.contact"
          required
        ></b-form-input>
      </div>
      <div class="item-input">
        <p>{{$t('CooperationContact.11')}}</p>
        <b-form-input
          type="number"
          :placeholder="$t('CooperationContact.11')"
          v-model="formContact.amount"
          min="0"
          required
        ></b-form-input>
      </div>
      <div class="item-input">
        <p>{{$t('CooperationContact.12')}}</p>
        <b-form-textarea
          rows="4"
          :placeholder="$t('CooperationContact.12')"
          v-model="formContact.order_info"
        ></b-form-textarea>
      </div>
      <div class="group-btn">
        <b-button type="submit" class="btn-send">{{$t('Support.223')}}</b-button>
        <b-button @click="resetForm" class="btn-reset">{{$t('CooperationContact.13')}}</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formContact: {
          email: '',
          name: '',
          project_name: '',
          project_website: '',
          with_link: 1,
          contact: '',
          amount: '',
          order_info: '',
        },
      };
    },
    methods: {
      onSendCooperationContact() {
        this.$store.dispatch(
          'dashboard/req_postCooperationContact',
          this.formContact
        );
      },
      resetForm() {
        this.formContact = {
          email: '',
          name: '',
          project_name: '',
          project_website: '',
          with_link: 1,
          contact: '',
          amount: '',
          order_info: '',
        };
      },
    },
    created() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        // eslint-disable-next-line default-case
        switch (mutation.type) {
          case 'dashboard/SEND_COOPERATION_CONTACT':
            this.formContact = {
              email: '',
              name: '',
              project_name: '',
              project_website: '',
              with_link: 1,
              contact: '',
              amount: '',
              order_info: '',
            };
            break;
        }
      });
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  };
</script>

<style lang="scss">
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #7bc514;
  }
  .contact {
    width: 100%;
    max-width: 1368px;
    position: relative;
    padding: 1.25rem;
    margin: 20px auto;
    h5 {
      color: #ffffff;
      margin-bottom: 15px;
    }
    p {
      color: #ffffff;
      margin-bottom: 15px;
      line-height: 120%;
    }
    .form-contact {
      width: 100%;
      max-width: 600px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0 auto;
      background-color: rgba(49, 52, 58, 1) !important;
      border-radius: 10px;
      padding: 10px;
      .item-input {
        width: 100%;
        p {
          color: #ffffff;
          margin-bottom: 5px;
          margin-top: 20px;
        }
        .form-control {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(212, 250, 77);
          }
        }
        fieldset {
          margin-bottom: 0px;
          .custom-control-label {
            color: #ffffff;
          }
        }
      }
      .group-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        button {
          margin: 0px 10px;
        }
        .btn-send {
          color: #f5f6f7;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #5da000;
          background-image: conic-gradient(from 1turn, #458802, #7bc514);
          border-radius: 6.25rem;
          font-weight: bold;
          border: none;
        }
        .btn-reset {
          color: #f5f6f7;
          box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
          background-color: #5da000;
          background-image: conic-gradient(from 1turn, #880202, #c51414);
          border-radius: 6.25rem;
          font-weight: bold;
          border: none;
        }
      }
    }
  }
</style>
